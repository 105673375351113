@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.slide-up-animation {
  animation: slideUp 0.5s ease forwards;
}

@keyframes slideFadeIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFadeOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.slide-enter {
  animation: slideFadeIn 1s forwards;
}

.slide-exit {
  animation: slideFadeOut 1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
  visibility: hidden;
  animation-fill-mode: forwards;
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.float-animation {
  animation: floatAnimation 2s ease-in-out infinite;
}

html {
  scroll-behavior: smooth;
}

a:hover,
a.active {
  font-weight: 600;
}

::placeholder {
  color: #181818 !important;
  opacity: 1;
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
}

h1 {
  font-size: 24px;
  font-weight: 600;
}

h1.title {
  font-size: 32px;
  font-weight: 600;
}

h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h4 {
  font-weight: 600;
  font-size: 16px;
}

h5 {
  font-weight: 600;
  font-size: 14px;
}

h5.subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #888888;
}

p {
  font-weight: 500;
  font-size: 14px;
}

li {
  font-size: 14px;
  font-weight: 500;
}
